body {
  margin: 0;
  font-family:
    "Inter",
    "Avenir",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fugaz One", sans-serif;
}

sub {
  color: #dc143c;
}
