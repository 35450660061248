.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  border: calc(2px + 1vmin) solid #bababa;
  border-radius: 50%;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: pulsate 1s infinite ease-in-out;
  }
}

.App-header {
  background-color: #000000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: relative;
}

.middle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; /* Control the gap between h3 and p here */
}

.bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  margin-bottom: calc(3rem + 1.5rem); /* error message's height + extra space */
}

.error-message {
  background-color: #dc143c;
  color: white;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  position: fixed; /* position fixed so it sticks to the bottom of the screen */
  bottom: 0; /* aligned to the bottom of the screen */
  left: 0; /* aligned to the left side of the screen */
}

.App-link {
  font-size: calc(4px + 2vmin);
  font-weight: 300;
  color: #61dafb;
}

.button-style {
  color: #ffffff; /* font color updated to white */
  background-color: #dc143c; /* background color updated to crimson */
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.5s ease;
}

.button-style:hover {
  background-color: #b00e28;
}

.button-style:active {
  background-color: #980a1f;
}

.button-style:disabled {
  background-color: #bababa;
  cursor: not-allowed;
}

@keyframes pulsate {
  0% {
    transform: scale(1); /* start at normal size */
  }
  25% {
    transform: scale(1.016); /* grow to 110% at halfway */
  }
  50% {
    transform: scale(1.02); /* grow to 110% at halfway */
  }
  75% {
    transform: scale(1.016); /* grow to 110% at halfway */
  }
  100% {
    transform: scale(1); /* return to normal size */
  }
}
